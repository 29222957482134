// src/components/AddNewEvent.js
import React, { useState } from "react";
import { db, storage } from "../firebase";
import { Timestamp, doc, getDoc, setDoc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import Modal from "./Modal";
import ReactMarkdown from 'react-markdown';

const AddNewEvent = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [venue, setVenue] = useState("");
  const [lastDate, setLastDate] = useState("");
  const [eventDate, setEventDate] = useState("");
  const [questions, setQuestions] = useState([]);
  const [question, setQuestion] = useState("");
  const [questionType, setQuestionType] = useState("text");
  const [options, setOptions] = useState([""]);
  const [bannerImage, setBannerImage] = useState(null);
  const [modal, setModal] = useState({ isVisible: false, title: "", message: "" });
  const [coupons, setCoupons] = useState([]);
  const [newCoupon, setNewCoupon] = useState({ code: '', discount: '', maxUses: 1 });
  const [questionSettings, setQuestionSettings] = useState({
    maxLength: '',
    maxChecks: '',
    minDate: '',
    maxDate: '',
    minValue: '',
    maxValue: '',
    maxSelections: '',
  });
  const [price, setPrice] = useState("0");
  const [eventID, setEventID] = useState("");
  const [availabilityStatus, setAvailabilityStatus] = useState(null);
  const [eventSections, setEventSections] = useState([]);
  const [newSection, setNewSection] = useState({ title: '', content: '', order: 0 });
  const [previewMode, setPreviewMode] = useState(false);
  const navigate = useNavigate();

  // Add validation constants
  const MAX_TITLE_LENGTH = 100;
  const MAX_DESC_LENGTH = 2000;
  const MAX_COUPONS = 5;
  const MAX_EVENTID_LENGTH = 30;
  const EVENTID_REGEX = /^[a-z0-9-]+$/;

  // Add 'textarea' and 'number' to question types
  const QUESTION_TYPES = ['text', 'textarea', 'number', 'dropdown', 'checkbox', 'radio', 'date'];

  const FIELD_NAMES = {
    LAST_DATE: 'lastDateToRegister',
    EVENT_DATE: 'eventDate'
  };

  const normalizeQuestion = (q) => ({
    questionText: q.question,
    questionType: q.type,
    options: ['dropdown', 'checkbox', 'radio'].includes(q.type) ? q.options : [],
    ...(q.settings || {})
  });

  const validateAndConvertDate = (dateString) => {
    return dateString ? Timestamp.fromDate(new Date(dateString)) : null;
  };

  const addQuestion = () => {
    if (question.trim() === "") return;
    
    const newQuestion = {
      question,
      questionText: question, // Store both for backwards compatibility
      type: questionType,
      questionType: questionType, // Store both for backwards compatibility
      options: ["dropdown", "checkbox", "radio"].includes(questionType) 
        ? options.filter(opt => opt.trim() !== "")
        : [],
      settings: {
        ...questionSettings,
        maxLength: questionSettings.maxLength ? Number(questionSettings.maxLength) : null,
        maxChecks: questionSettings.maxChecks ? Number(questionSettings.maxChecks) : null,
        minValue: questionSettings.minValue ? Number(questionSettings.minValue) : null,
        maxValue: questionSettings.maxValue ? Number(questionSettings.maxValue) : null,
      }
    };

    setQuestions([...questions, newQuestion]);
    setQuestion("");
    setQuestionType("text");
    setOptions([""]);
    setQuestionSettings({
      maxLength: '',
      maxChecks: '',
      minDate: '',
      maxDate: '',
    });
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  const addOption = () => {
    setOptions([...options, ""]);
  };

  const removeOption = (index) => {
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 1048576) { // 1MB
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        if (img.width >= 290 && img.height >= 160) {
          setBannerImage(file);
        } else {
          setModal({ isVisible: true, title: "Invalid Image", message: "Image dimensions should be at least 290x160px." });
        }
      };
    } else {
      setModal({ isVisible: true, title: "Invalid File", message: "File size should not exceed 1MB." });
    }
  };

  const handleImageUpload = async (file) => {
    const storageRef = ref(storage, `banners/${eventID}_${file.name}`);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Validate EventID
    if (!EVENTID_REGEX.test(eventID) || eventID.length > MAX_EVENTID_LENGTH) {
      setModal({ isVisible: true, title: "Invalid Event ID", message: "Event ID must be lowercase, English letters, numbers, hyphens (-) only, and up to 30 characters." });
      return;
    }
    try {
      // Check if EventID already exists
      const eventDoc = await getDoc(doc(db, "events", eventID));
      if (eventDoc.exists()) {
        setModal({ isVisible: true, title: "Error", message: "Event ID already exists. Please choose a different one." });
        return;
      }
      let bannerImageUrl = "";
      if (bannerImage) {
        bannerImageUrl = await handleImageUpload(bannerImage);
      }

      const eventData = {
        title,
        description,
        venue,
        [FIELD_NAMES.LAST_DATE]: validateAndConvertDate(lastDate),
        [FIELD_NAMES.EVENT_DATE]: validateAndConvertDate(eventDate),
        questions: questions.map(normalizeQuestion),
        bannerImageUrl,
        bannerImage: bannerImageUrl, // For backwards compatibility
        price: Number(price),
        coupons: coupons.map(coupon => ({
          ...coupon,
          discount: Number(coupon.discount),
          maxUses: Number(coupon.maxUses)
        })),
        eventSections: eventSections.map((section, index) => ({
          sectionId: section.id,
          id: section.id,
          title: section.title,
          content: section.content,
          order: section.order || index
        })),
        createdAt: Timestamp.now(),
        updatedAt: Timestamp.now(),
        eventID
      };

      await setDoc(doc(db, "events", eventID), eventData);
      setModal({ isVisible: true, title: "Success", message: "Event added successfully!" });
      navigate("/admin");
    } catch (error) {
      console.error("Error adding event:", error);
      setModal({ isVisible: true, title: "Error", message: "Failed to add event." });
    }
  };

  const checkEventIDAvailability = async () => {
    if (!EVENTID_REGEX.test(eventID) || eventID.length > MAX_EVENTID_LENGTH) {
      setAvailabilityStatus({ available: false, message: "Invalid Event ID format." });
      return;
    }
    try {
      const eventDoc = await getDoc(doc(db, "events", eventID));
      if (eventDoc.exists()) {
        setAvailabilityStatus({ available: false, message: "Event ID is already taken." });
      } else {
        setAvailabilityStatus({ available: true, message: "Event ID is available." });
      }
    } catch (error) {
      console.error("Error checking Event ID:", error);
      setAvailabilityStatus({ available: false, message: "Error checking Event ID." });
    }
  };

  const addCoupon = () => {
    if (coupons.length >= MAX_COUPONS) {
      setModal({ isVisible: true, title: "Error", message: "Maximum 5 coupons allowed" });
      return;
    }
    if (!newCoupon.code || !newCoupon.discount) return;
    setCoupons([...coupons, newCoupon]);
    setNewCoupon({ code: '', discount: '', maxUses: 1 });
  };

  const handleSectionAdd = () => {
    if (!newSection.title.trim() || !newSection.content.trim()) return;
    setEventSections([...eventSections, { ...newSection, id: Date.now() }]);
    setNewSection({ title: '', content: '', order: eventSections.length });
  };

  const handleSectionRemove = (sectionId) => {
    setEventSections(eventSections.filter(section => section.id !== sectionId));
  };

  const renderQuestionSettings = () => {
    switch(questionType) {
      case 'text':
      case 'textarea':
        return (
          <div className="mt-2">
            <label>Max Answer Length</label>
            <input
              type="number"
              min="1"
              className="w-full p-2 border rounded"
              value={questionSettings.maxLength}
              onChange={(e) => setQuestionSettings({...questionSettings, maxLength: e.target.value})}
            />
          </div>
        );
      case 'number':
        return (
          <div className="mt-2 space-y-2">
            <div>
              <label>Minimum Value</label>
              <input
                type="number"
                className="w-full p-2 border rounded"
                value={questionSettings.minValue}
                onChange={(e) => setQuestionSettings({...questionSettings, minValue: e.target.value})}
              />
            </div>
            <div>
              <label>Maximum Value</label>
              <input
                type="number"
                className="w-full p-2 border rounded"
                value={questionSettings.maxValue}
                onChange={(e) => setQuestionSettings({...questionSettings, maxValue: e.target.value})}
              />
            </div>
          </div>
        );
      case 'checkbox':
        return (
          <div className="mt-2">
            <label>Max Selections</label>
            <input
              type="number"
              min="1"
              className="w-full p-2 border rounded"
              value={questionSettings.maxChecks}
              onChange={(e) => setQuestionSettings({...questionSettings, maxChecks: e.target.value})}
            />
          </div>
        );
      case 'date':
        return (
          <div className="mt-2 space-y-2">
            <div>
              <label>Minimum Date (Optional)</label>
              <input
                type="date"
                className="w-full p-2 border rounded"
                value={questionSettings.minDate}
                onChange={(e) => setQuestionSettings({...questionSettings, minDate: e.target.value})}
              />
            </div>
            <div>
              <label>Maximum Date (Optional)</label>
              <input
                type="date"
                className="w-full p-2 border rounded"
                value={questionSettings.maxDate}
                onChange={(e) => setQuestionSettings({...questionSettings, maxDate: e.target.value})}
              />
            </div>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <motion.div
      className="w-full"
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.2 }}
    >
      <div className="bg-white p-6 rounded shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Add New Event</h2>
        <form onSubmit={handleSubmit}>
          {/* Event Title */}
          <div className="mb-4">
            <label className="block mb-1">Event Title</label>
            <input
              type="text"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              placeholder="Event Title"
              maxLength={MAX_TITLE_LENGTH}
            />
            <span className="text-sm text-gray-500">{title.length}/{MAX_TITLE_LENGTH}</span>
          </div>
          {/* Event Description */}
          <div className="mb-4">
            <label className="block mb-1">Event Description</label>
            <textarea
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              placeholder="Event Description"
              rows={4}
              maxLength={MAX_DESC_LENGTH}
            ></textarea>
            <span className="text-sm text-gray-500">{description.length}/{MAX_DESC_LENGTH}</span>
          </div>
          {/* Venue */}
          <div className="mb-4">
            <label className="block mb-1">Venue</label>
            <input
              type="text"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={venue}
              onChange={(e) => setVenue(e.target.value)}
              required
              placeholder="Event Venue"
            />
          </div>
          {/* Registration Price */}
          <div className="mb-4">
            <label className="block mb-1">Registration Price (₹)</label>
            <input
              type="number"
              min="0"
              step="1"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
              required
              placeholder="Enter registration price"
            />
            <span className="text-sm text-gray-500">Set 0 for free registration</span>
          </div>
          {/* Last Day for Registration */}
          <div className="mb-4">
            <label className="block mb-1">Last Day for Registration</label>
            <input
              type="date"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={lastDate}
              onChange={(e) => setLastDate(e.target.value)}
              required
            />
          </div>
          {/* Day of the Event */}
          <div className="mb-4">
            <label className="block mb-1">Day of the Event</label>
            <input
              type="date"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={eventDate}
              onChange={(e) => setEventDate(e.target.value)}
              required
            />
          </div>
          {/* Banner Image */}
          <div className="mb-4">
            <label className="block mb-1">Banner Image</label>
            <input type="file" accept="image/*" onChange={handleImageChange} className="w-full" />
            {bannerImage && (
              <p className="text-sm text-gray-600 mt-2">Image ready for upload.</p>
            )}
          </div>
          {/* Event ID */}
          <div className="mb-4">
            <label className="block mb-1">Event ID</label>
            <input
              type="text"
              className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
              value={eventID}
              onChange={(e) => setEventID(e.target.value)}
              required
              placeholder="Enter Event ID"
              maxLength={MAX_EVENTID_LENGTH}
            />
            <button
              type="button"
              onClick={checkEventIDAvailability}
              className="mt-2 bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
            >
              Check Availability
            </button>
            {availabilityStatus && (
              <p className={`text-sm mt-1 ${availabilityStatus.available ? 'text-green-500' : 'text-red-500'}`}>
                {availabilityStatus.message}
              </p>
            )}
            <span className="text-sm text-gray-500">
              {eventID.length}/{MAX_EVENTID_LENGTH} - Lowercase letters, numbers, hyphens only.
            </span>
            <p className="text-sm text-gray-500 mt-1">
              Rules:
              <ul className="list-disc list-inside">
                <li>Lowercase only</li>
                <li>English characters</li>
                <li>No special characters</li>
                <li>Spaces are replaced with hyphens (-)</li>
                <li>Maximum 30 characters</li>
                <li>Cannot be updated later</li>
                <li>Example URL: events.youthxconnect.com/event/indian-diplomacy-summit-2025</li>
              </ul>
            </p>
          </div>
          {/* Custom Registration Questions */}
          <div className="mb-4">
            <h3 className="text-xl font-semibold mb-2">Custom Registration Questions</h3>
            {questions.map((q, index) => (
              <div key={index} className="mb-2">
                <span className="font-medium">
                  {index + 1}. {q.question} ({q.type})
                </span>
                {["dropdown", "checkbox", "radio"].includes(q.type) && q.options.length > 0 && (
                  <ul className="list-disc list-inside mt-1">
                    {q.options.map((opt, idx) => (
                      <li key={idx}>{opt}</li>
                    ))}
                  </ul>
                )}
              </div>
            ))}
            {/* Add Question Section */}
            <div className="border p-4 rounded mt-4">
              <div className="flex flex-col mb-2">
                <label className="mb-1">Question</label>
                <input
                  type="text"
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  placeholder="Enter your question"
                />
              </div>
              <div className="flex flex-col mb-2">
                <label className="mb-1">Question Type</label>
                <select
                  className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                  value={questionType}
                  onChange={(e) => {
                    setQuestionType(e.target.value);
                    setQuestionSettings({
                      maxLength: '',
                      maxChecks: '',
                      minDate: '',
                      maxDate: '',
                    });
                  }}
                >
                  {QUESTION_TYPES.map(type => (
                    <option key={type} value={type}>{type.charAt(0).toUpperCase() + type.slice(1)}</option>
                  ))}
                </select>
              </div>
              {["dropdown", "checkbox", "radio"].includes(questionType) && (
                <div className="flex flex-col mb-2">
                  <label className="mb-1">Options</label>
                  {options.map((opt, idx) => (
                    <div key={idx} className="flex items-center mb-1">
                      <input
                        type="text"
                        className="w-full p-2 border rounded focus:ring-2 focus:ring-blue-500"
                        value={opt}
                        onChange={(e) => handleOptionChange(idx, e.target.value)}
                        placeholder={`Option ${idx + 1}`}
                      />
                      <button
                        type="button"
                        onClick={() => removeOption(idx)}
                        className="ml-2 bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                      >
                        Remove
                      </button>
                    </div>
                  ))}
                  <button
                    type="button"
                    onClick={addOption}
                    className="mt-2 bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
                  >
                    Add Option
                  </button>
                </div>
              )}
              {renderQuestionSettings()}
              <button
                type="button"
                className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                onClick={addQuestion}
              >
                Add Question
              </button>
            </div>
          </div>
          {/* Coupon System */}
          <div className="mb-4 border-t pt-4">
            <h3 className="text-xl font-semibold mb-2">Coupons ({coupons.length}/{MAX_COUPONS})</h3>
            <div className="flex gap-2 mb-2">
              <input
                type="text"
                placeholder="Coupon Code"
                className="flex-1 p-2 border rounded"
                value={newCoupon.code}
                onChange={(e) => setNewCoupon({...newCoupon, code: e.target.value.toUpperCase()})}
              />
              <input
                type="number"
                placeholder="Discount %"
                className="w-24 p-2 border rounded"
                value={newCoupon.discount}
                onChange={(e) => setNewCoupon({...newCoupon, discount: Math.min(100, Math.max(0, e.target.value))})}
              />
              <input
                type="number"
                placeholder="Max Uses"
                className="w-24 p-2 border rounded"
                value={newCoupon.maxUses}
                onChange={(e) => setNewCoupon({...newCoupon, maxUses: Math.max(1, e.target.value)})}
              />
              <button
                type="button"
                onClick={addCoupon}
                className="bg-green-500 text-white px-4 rounded hover:bg-green-600"
              >
                Add
              </button>
            </div>
            {/* Display added coupons */}
            {coupons.map((coupon, idx) => (
              <div key={idx} className="flex items-center gap-2 mb-1 bg-gray-50 p-2 rounded">
                <span className="font-mono">{coupon.code}</span>
                <span>{coupon.discount}% off</span>
                <span>({coupon.maxUses} uses)</span>
                <button
                  type="button"
                  onClick={() => setCoupons(coupons.filter((_, i) => i !== idx))}
                  className="ml-auto text-red-500 hover:text-red-700"
                >
                  Remove
                </button>
              </div>
            ))}
          </div>
          {/* Event Information Sections */}
          <div className="mb-6 border-t pt-4">
            <h3 className="text-xl font-semibold mb-4">Event Information Sections</h3>
            
            {/* Existing Sections */}
            {eventSections.map((section, index) => (
              <div key={section.id} className="mb-4 p-4 border rounded">
                <div className="flex justify-between items-center mb-2">
                  <h4 className="font-semibold">{section.title}</h4>
                  <button
                    type="button"
                    onClick={() => handleSectionRemove(section.id)}
                    className="text-red-500 hover:text-red-700"
                  >
                    Remove
                  </button>
                </div>
                {previewMode ? (
                  <div className="prose max-w-none">
                    <ReactMarkdown>{section.content}</ReactMarkdown>
                  </div>
                ) : (
                  <textarea
                    value={section.content}
                    onChange={(e) => {
                      const updatedSections = [...eventSections];
                      updatedSections[index].content = e.target.value;
                      setEventSections(updatedSections);
                    }}
                    className="w-full p-2 border rounded min-h-[100px]"
                  />
                )}
              </div>
            ))}

            {/* Add New Section */}
            <div className="border p-4 rounded">
              <h4 className="font-semibold mb-2">Add New Section</h4>
              <input
                type="text"
                placeholder="Section Title"
                value={newSection.title}
                onChange={(e) => setNewSection({ ...newSection, title: e.target.value })}
                className="w-full p-2 border rounded mb-2"
              />
              <textarea
                placeholder="Section Content (Markdown supported)"
                value={newSection.content}
                onChange={(e) => setNewSection({ ...newSection, content: e.target.value })}
                className="w-full p-2 border rounded mb-2 min-h-[100px]"
              />
              <button
                type="button"
                onClick={handleSectionAdd}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
              >
                Add Section
              </button>
            </div>

            {/* Preview Toggle */}
            <button
              type="button"
              onClick={() => setPreviewMode(!previewMode)}
              className="mt-4 bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-600"
            >
              {previewMode ? 'Edit Mode' : 'Preview Mode'}
            </button>
          </div>
          <button
            type="submit"
            className="w-full bg-blue-600 text-white p-2 rounded hover:bg-blue-700 transition-colors"
          >
            Create Event
          </button>
        </form>
        <Modal
          isVisible={modal.isVisible}
          title={modal.title}
          message={modal.message}
          onClose={() => setModal({ isVisible: false, title: "", message: "" })}
        />
      </div>
    </motion.div>
  );
};

export default AddNewEvent;