// src/components/ExistingEvents.js
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { collection, getDocs, deleteDoc, doc, Timestamp } from 'firebase/firestore';
import { db } from '../firebase';
import Modal from './Modal';
import { format } from 'date-fns';

const ExistingEvents = ({ onEditEvent }) => {
  const [events, setEvents] = useState([]);
  const [modal, setModal] = useState({ isVisible: false, title: "", message: "", onConfirm: null });

  const fetchEvents = async () => {
    const eventsCollection = collection(db, "events");
    const eventSnapshot = await getDocs(eventsCollection);
    const eventList = eventSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setEvents(eventList);
  };

  const handleDelete = (id) => {
    setModal({
      isVisible: true,
      title: "Confirm Deletion",
      message: "Are you sure you want to delete this event?",
      onConfirm: async () => {
        try {
          await deleteDoc(doc(db, "events", id));
          setModal({ isVisible: false, title: "", message: "", onConfirm: null });
          fetchEvents();
        } catch (error) {
          console.error("Error deleting event:", error);
          setModal({
            isVisible: true,
            title: "Error",
            message: "Failed to delete event.",
            onConfirm: null
          });
        }
      }
    });
  };

  const handleEdit = (eventId) => {
    onEditEvent(eventId); // Trigger the edit event handler passed from AdminDashboard
  };

  useEffect(() => {
    fetchEvents();
  }, []);

  return (
    <motion.div
      className="w-full"
      initial={{ y: 20, opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.2 }}
    >
      <div className="bg-white p-6 rounded shadow-md">
        <h2 className="text-2xl font-semibold mb-4">Existing Events</h2>
        {events.length === 0 ? (
          <p className="text-gray-500">No events found.</p>
        ) : (
          <table className="w-full table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2 border-b">Title</th>
                <th className="px-4 py-2 border-b">Last Registration</th>
                <th className="px-4 py-2 border-b">Event Date</th>
                <th className="px-4 py-2 border-b">Actions</th>
              </tr>
            </thead>
            <tbody>
              {events.map((event) => (
                <tr key={event.id} className="text-center">
                  <td className="border px-4 py-2">{event.title}</td>
                  <td className="border px-4 py-2">
                    {event.lastDateToRegister instanceof Timestamp ? format(event.lastDateToRegister.toDate(), 'yyyy-MM-dd') : 'N/A'}
                  </td>
                  <td className="border px-4 py-2">
                    {event.eventDate instanceof Timestamp ? format(event.eventDate.toDate(), 'yyyy-MM-dd') : 'N/A'}
                  </td>
                  <td className="border px-4 py-2">
                    <button
                      onClick={() => handleEdit(event.id)}
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                      Edit
                    </button>
                    <button
                      onClick={() => handleDelete(event.id)}
                      className="text-red-500 hover:underline"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Modal
        isVisible={modal.isVisible}
        title={modal.title}
        message={modal.message}
        onClose={() => setModal({ isVisible: false, title: "", message: "", onConfirm: null })}
        onConfirm={modal.onConfirm}
      />
    </motion.div>
  );
};

export default ExistingEvents;