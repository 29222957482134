// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyAzdGbjfKtyJePO7PTZiyDe0x1jjmd4Zv8",
    authDomain: "youthxconnect-site.firebaseapp.com",
    projectId: "youthxconnect-site",
    storageBucket: "youthxconnect-site.appspot.com",
    messagingSenderId: "53765282807",
    appId: "1:53765282807:web:f8057a868504a11ffb6cf2",
    measurementId: "G-1HMHGKZ5K0"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export { auth, db, storage };