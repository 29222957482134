// src/pages/Login.js
import React, { useState, useEffect, useRef } from 'react';
import { auth, db } from '../firebase';
import { signInWithEmailAndPassword, signOut, onAuthStateChanged } from 'firebase/auth';
import { query, where, getDocs, collection } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import Modal from '../components/Modal';

const Login = ({ setUser }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [captchaValue, setCaptchaValue] = useState(null);
  const [attempted, setAttempted] = useState(false);
  const [modal, setModal] = useState({ isVisible: false, title: "", message: "" });
  const navigate = useNavigate();
  const captchaRef = useRef(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        navigate('/admin');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setAttempted(false);
  };

  const resetCaptcha = () => {
    setCaptchaValue(null);
    if (captchaRef.current) {
      captchaRef.current.reset();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleLogin();
    }
  };

  const handleLogin = async () => {
    if (!email || !password) {
      setModal({ isVisible: true, title: "Error", message: "Please enter both email and password." });
      resetCaptcha();
      setAttempted(false);
      return;
    }
  
    if (!captchaValue || attempted) {
      setModal({ isVisible: true, title: "Error", message: "Please complete the captcha before logging in." });
      resetCaptcha();
      setAttempted(false);
      return;
    }

    setIsLoading(true);
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log('User signed in:', user);

      const userQuery = query(collection(db, 'users'), where('email', '==', email));
      const querySnapshot = await getDocs(userQuery);

      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        console.log('User Data:', userData);

        if (userData.userType === 'Admin') {
          setUser(user);
          navigate('/admin');
        } else {
          await signOut(auth);
          setUser(null);
          setModal({ isVisible: true, title: "Access Denied", message: "Access denied. Admins only." });
          console.warn('Access denied for user:', email);
        }
      } else {
        await signOut(auth);
        setUser(null);
        setModal({ isVisible: true, title: "Error", message: "No user document found for this email." });
        console.warn('No user document found for:', email);
      }
      setAttempted(true);
      resetCaptcha();
    } catch (error) {
      console.error('Error during login:', error);
      const errorMessages = {
        'auth/user-not-found': "No user found with this email.",
        'auth/wrong-password': "Incorrect password. Please try again.",
        'auth/too-many-requests': "Too many failed attempts. Please try again later.",
        'default': "Login failed. Please check your credentials."
      };
      setModal({ 
        isVisible: true, 
        title: "Error", 
        message: errorMessages[error.code] || errorMessages.default 
      });
      resetCaptcha();
      setAttempted(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="p-8 bg-white shadow-md rounded-md w-full max-w-md">
        <h2 className="text-2xl font-semibold text-center mb-6">Admin Login</h2>
        <input
          type="email"
          className="w-full p-3 mb-4 border rounded"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyPress={handleKeyPress}
          required
        />
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            className="w-full p-3 mb-4 border rounded pr-10"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyPress={handleKeyPress}
            required
          />
          <button
            type="button"
            className="absolute right-3 top-3 text-gray-500 hover:text-gray-700"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? "Hide" : "Show"}
          </button>
        </div>
        <ReCAPTCHA
          ref={captchaRef}
          sitekey="6Leys3EqAAAAAEjkHcbcAjaoJ1Bf4o1xcG8W1Hv9"
          onChange={handleCaptchaChange}
        />
        <button
          type="button"
          className={`w-full p-3 bg-blue-600 text-white rounded hover:bg-blue-700 transition duration-200 mt-4 
            ${(!captchaValue || isLoading) ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={handleLogin}
          disabled={!captchaValue || isLoading}
        >
          {isLoading ? (
            <span>Logging in...</span>
          ) : (
            <span>Log In</span>
          )}
        </button>
      </div>
      <Modal
        isVisible={modal.isVisible}
        title={modal.title}
        message={modal.message}
        onClose={() => setModal({ isVisible: false, title: "", message: "" })}
      />
    </div>
  );
};

export default Login;