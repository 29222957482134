// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, deleteDoc, doc, Timestamp } from 'firebase/firestore';
import Modal from './Modal';
import { format } from 'date-fns';

const Dashboard = () => {
  const [registrations, setRegistrations] = useState([]);
  const [events, setEvents] = useState([]);
  const [statistics, setStatistics] = useState({ totalEvents: 0, totalRegistrations: 0 });
  const [modal, setModal] = useState({ isVisible: false, title: '', message: '', onConfirm: null });

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch events from the 'events' collection
        const eventsSnapshot = await getDocs(collection(db, 'events'));
        const eventsData = eventsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setEvents(eventsData);

        // Fetch registrations from the top-level 'registrations' collection
        const registrationsSnapshot = await getDocs(collection(db, 'registrations'));
        const registrationsData = registrationsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setRegistrations(registrationsData);

        // Update statistics
        setStatistics({
          totalEvents: eventsSnapshot.size,
          totalRegistrations: registrationsSnapshot.size,
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleDelete = (id) => {
    setModal({
      isVisible: true,
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this registration?',
      onConfirm: () => confirmDelete(id),
    });
  };

  const confirmDelete = async (id) => {
    try {
      // Delete the registration document from the top-level 'registrations' collection
      await deleteDoc(doc(db, 'registrations', id));
      setRegistrations(registrations.filter(reg => reg.id !== id));
      setStatistics(prev => ({ ...prev, totalRegistrations: prev.totalRegistrations - 1 }));
      setModal({ isVisible: false, title: '', message: '', onConfirm: null });
    } catch (error) {
      console.error('Error deleting registration:', error);
      setModal({
        isVisible: true,
        title: 'Error',
        message: 'Failed to delete registration.',
        onConfirm: null,
      });
    }
  };

  return (
    <div>
      {/* Statistics Section */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Statistics</h3>
        <div className="grid grid-cols-2 gap-4">
          <div className="p-4 bg-blue-100 rounded">
            <p className="text-gray-700">Total Events</p>
            <p className="text-2xl font-bold">{statistics.totalEvents}</p>
          </div>
          <div className="p-4 bg-green-100 rounded">
            <p className="text-gray-700">Total Registrations</p>
            <p className="text-2xl font-bold">{statistics.totalRegistrations}</p>
          </div>
        </div>
      </div>

      {/* Events Table */}
      <div className="mb-8">
        <h3 className="text-xl font-semibold mb-4">Events</h3>
        {events.length === 0 ? (
          <p className="text-gray-500">No events found.</p>
        ) : (
          <table className="w-full table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2 border">Title</th>
                <th className="px-4 py-2 border">Event Date</th>
                <th className="px-4 py-2 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {events.map(event => (
                <tr key={event.id}>
                  <td className="px-4 py-2 border">{event.title}</td>
                  <td className="px-4 py-2 border">
                    {event.eventDate instanceof Timestamp ? format(event.eventDate.toDate(), 'yyyy-MM-dd') : 'N/A'}
                  </td>
                  <td className="px-4 py-2 border">
                    <button
                      onClick={() => handleDelete(event.id)}
                      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Registrations Table */}
      <div>
        <h3 className="text-xl font-semibold mb-4">Registrations</h3>
        {registrations.length === 0 ? (
          <p className="text-gray-500">No registrations found.</p>
        ) : (
          <table className="w-full table-auto">
            <thead>
              <tr>
                <th className="px-4 py-2 border">User ID</th>
                <th className="px-4 py-2 border">Event</th>
                <th className="px-4 py-2 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {registrations.map(reg => (
                <tr key={reg.id}>
                  <td className="px-4 py-2 border">{reg.userId}</td>
                  <td className="px-4 py-2 border">{reg.eventId}</td>
                  <td className="px-4 py-2 border">
                    <button
                      onClick={() => handleDelete(reg.id)}
                      className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-600"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>

      {/* Modal for Confirmation */}
      <Modal
        isVisible={modal.isVisible}
        title={modal.title}
        message={modal.message}
        onClose={() => setModal({ isVisible: false, title: '', message: '', onConfirm: null })}
        onConfirm={modal.onConfirm}
      />
    </div>
  );
};

export default Dashboard;